// import Logo from "../../assets/images/logo-full.svg";
import NewLogo from "../../assets/images/AIms-Logo.jpg";
import React from "react";

import CreateAccount from "./CreateAccount";
import Login from "./Login";

class Landing extends React.Component {
  state = {
    currentTabIndex: 0,
  };

  tabClicked = (index) => {
    this.setState({ currentTabIndex: index });
  };

  render() {
    const { currentTabIndex } = this.state;
    return (
      <main>
        <div className="landing">
          <div className="landing-left w-50"></div>
          <div className="landing-right w-50">
            <div className="landing-wrapper">
              <div className="logo">
                <img src={NewLogo} alt="" />
              </div>
              <div className="tabs-wrapper">
                <div className="tabs">
                  <ul>
                    <li
                      className={
                        this.state.currentTabIndex === 0 ? "active" : ""
                      }
                    >
                      <button onClick={() => this.tabClicked(0)}>Log In</button>
                    </li>
                    <li
                      className={
                        this.state.currentTabIndex === 1 ? "active" : ""
                      }
                    >
                      <button onClick={() => this.tabClicked(1)}>
                        Coach Signup
                      </button>
                    </li>
                  </ul>
                </div>

                {currentTabIndex === 0 && <Login />}
                {currentTabIndex === 1 && <CreateAccount />}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Landing;
